import React from "react";

// Customizable Area Start
import { Box, Typography, Accordion, AccordionSummary, AccordionDetails, Grid, Dialog, Paper } from "@material-ui/core";
import SwitchButton from "../../dashboard/src/common/SwitchButton.web";
import { createTheme, ThemeProvider, styled } from "@material-ui/core/styles";
import {check, tick, subscibed, sadFace, popup} from "./assets";
import { Link } from "react-router-dom";

const theme = createTheme({
    palette: {
        primary: {
            main: "#0000ff",
            contrastText: "#fff",
        },
    }
});

const CustomAccordion = styled(Accordion)({
    root: {
        boxShadow: 'none',
        '&:before': {
            display: 'none',
            backgroundColor: 'white'
        },
        '&$expanded': {
            margin: 'auto',
        },
    },
    expanded: {},

})

const SubscribeButton = styled('button')({
    display: 'flex',
    gap: '8px',
    justifyContent: 'center',
    backgroundColor: '#43D270',
    height: "40px",
    padding: "0px 16px",
    alignItems: "center",
    color: '#34373A',
    borderRadius: "8px",
    cursor: 'pointer',
    border: 'none',
    width: '100%',
    fontFamily: "Inter",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "160%"
});

const CustomCard = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    width: '420px',
    padding: '20px 20px 0 20px',
    borderRadius: '10px',
    border: '0.6px solid rgba(52, 55, 58, 0.20)',
    background: '#FFF',
    boxShadow: '0px 4px 2px 0px rgba(0, 0, 0, 0.04)',
    gap: '10px',
    '@media(max-width: 768px)': {
        width: '100%',
        padding: '10px'
    },
});

const HeaderContainer = styled(Box)({
    display: "flex",
    justifyContent: 'space-between',
    padding: "0px 48px",
    width: '966px',
    '@media(max-width: 960px)': {
        flexDirection: "column",
        width: "auto",
        gap: 10
    }
});

const GridItem = styled(Grid)({
    maxWidth: 445,
    '@media(max-width: 768px)': {
        maxWidth: "100%"
    }
})

const CustomPaper = styled(Paper)({
    borderRadius: 16,
    padding: 40,
    '@media(max-width: 425px)': {
        padding: "30px 10px"
    }
  });

const ToggleTitle = styled(Typography)({
    fontFamily: 'Lato',
    fontWeight: 500,
    fontSize: "16px",
    lineHeight: "normal",
    color: "#7D7D7F",
    margin: "8px 0",
    '@media(max-width: 425px)': {
        fontSize: "15px",
    }
});
// Customizable Area End

import CustomisableusersubscriptionsController, {
    Props,
} from "./CustomisableusersubscriptionsController";

export default class Plans extends CustomisableusersubscriptionsController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    async componentDidMount() {
        this.getPlans();
        this.getHighestTierPlanData();
    }
    // Customizable Area End

    render() {
        // Customizable Area Start
        const { expanded } = this.state;
        let showYearlyData = this.state.filter;
        if (!this.props.showMonthYearToggle) {
            showYearlyData = true;
        }
        
        return (
            <>
                <ThemeProvider theme={theme}>
                    <HeaderContainer>
                        <Box style={webStyle.titleContainer}>
                            <Typography style={webStyle.title}>Upgrade to a Premium Plan</Typography>
                            <Typography style={webStyle.description}>10% Off on the Yearly Plan, Get personalised features in premium plans</Typography>
                        </Box>
                        {
                            this.props.showMonthYearToggle && (
                                <Box data-test-id="month-year-toggle-switch" style={webStyle.switchContainer}>
                                    <Typography style={webStyle.label}>Toggle Monthly / Yearly Prices</Typography>
                                    <SwitchButton handleChange={this.handleChange} checked={this.state.filter} index={0} id={0} />
                                </Box>
                            )
                        }
                    </HeaderContainer>
                    <Box p="35px 45px 0 45px" width={"100%"} >
                        <Grid container spacing={2} style={webStyle.cardsContainer}>
                            {
                                this.state.subscriptions.length !== 0 && this.state.subscriptions?.map((item) => (
                                <GridItem 
                                    item 
                                    xs={12} 
                                    md={6}
                                >
                                        <CustomCard data-test-id="plan-card">
                                            <Box style={webStyle.innerBox}>
                                                <Typography data-test-id="plan-name" style={webStyle.cardlabel}>{item.level}</Typography>
                                                <Box style={webStyle.priceContainer}>
                                                    <Typography 
                                                        data-test-id={`amount-text-${item.level}`}
                                                        style={{
                                                            ...webStyle.price,
                                                            display: item.level === this.state.highestTierPlanName ? "none" : "block",
                                                        }}
                                                    >{`${item.currency_type ?? "SGD "}${showYearlyData ? item.amount_yearly ?? "" : item.amount ?? ""} `}</Typography>
                                                    <Typography style={webStyle.permonth}>/{showYearlyData ? "year" : "month"}</Typography>
                                                </Box>
                                            </Box>
                                            <CustomAccordion elevation={0}
                                                style={{
                                                    width: "100%",
                                                    borderTop: expanded === item.id ? "1px solid rgba(52, 55, 58, 0.15)" : "none",
                                                    marginTop: expanded === item.id ? "-2px" : "0"
                                                }}
                                                key={item.id} expanded={expanded === item.id}
                                                onChange={this.handleChangeAccordian(item.id)}
                                                data-test-id={`expand${item.id}`}
                                            >
                                                <AccordionSummary
                                                    expandIcon={
                                                    <svg width="24" height="24" viewBox="0 0 24 24"
                                                                     fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M6 9L12 15L18 9" stroke="#7D7D7F" stroke-width="2"
                                                              stroke-linecap="round" stroke-linejoin="round"/>
                                                    </svg>}
                                                    aria-controls="panel1a-content"
                                                    id="panel1a-header"
                                                >
                                                {
                                                        item.subscribed ?
                                                            <Typography style={webStyle.subscribe}>
                                                                <img data-test-id="check-icon" src={subscibed} alt="check" />
                                                                Subscribed
                                                            </Typography> :
                                                            <ToggleTitle>What’s Included ?</ToggleTitle>
                                                    }
                                                </AccordionSummary>
                                                <AccordionDetails style={webStyle.titleContainer}>
                                                    <Box width="100%">
                                                        <Grid container spacing={2}>
                                                            {
                                                                item.selected_pricing_features.filter((items) => items.status).map((features) => (
                                                                    <Grid item xs={12} sm={6}>
                                                                        <Box display="flex" gridGap="12px">
                                                                            <img height={22} width={22} src={check} alt="" />
                                                                            <Typography style={webStyle.services}>{features.name}</Typography>
                                                                        </Box>
                                                                    </Grid>
                                                                ))
                                                            }
                                                        </Grid>
                                                    </Box>
                                                    {
                                                        (item.subscribed && this.checkSubscription() === item.subscription_type) || this.account_type === "portfolio_manager" || item.level === "FREEMIUM" ? <></> :
                                                            (<SubscribeButton data-test-id={`subscribe${item.id}`}
                                                                              onClick={() => this.handleSubscribe(item.id, item.disabled, item.amount, item.level)}>
                                                                <img src={tick} alt="tick" />Subscribe {item?.level}
                                                            </SubscribeButton>)
                                                    }
                                                </AccordionDetails>
                                            </CustomAccordion>
                                        </CustomCard>
                                    </GridItem>
                                ))
                            }
                        </Grid>
                        <Typography style={webStyle.footertext}>
                            <Link style={{color: "#42d270", textDecoration: "none"} as React.CSSProperties}
                                  to="/terms-and-conditions"> Terms and Conditions </Link>
                        </Typography>
                    </Box>
                </ThemeProvider>
                <Dialog
                    open={this.state.showDowngradeSubscriptionAlert}
                    onClose={this.handleCloseDowngradeSubscriptionAlert}
                    aria-labelledby="Account Downgrade Restricted"
                    aria-describedby="downgrading is currently not permitted"
                    PaperComponent={CustomPaper}
                >
                    <img src={sadFace} style={webStyle.dialogImg} alt="" />
                    <Typography style={webStyle.dialogTitle}>
                        Account Downgrade Restricted
                    </Typography>
                    <Typography style={webStyle.dialogContent}>
                        We're sorry, but downgrading is currently not permitted for your
                        account. Please reach out to our support team at{" "}
                        <a href="mailto:support@carbonzeroed.com" style={webStyle.emailLink}>support@carbonzeroed.com</a> for assistance.
                    </Typography>
                    <Typography style={webStyle.ctaText}>
                        Go to{" "}
                        <span
                            style={webStyle.ctaLink}
                            onClick={this.handleCloseDowngradeSubscriptionAlert}
                        >
                            Subscription
                        </span>
                        {" "}page
                    </Typography>
                </Dialog>
                <Dialog
                  open={this.state.showAmountAlert}
                  onClose={this.handleCloseAmountAlert}
                  aria-labelledby="Upgrade to Pricing Plan"
                  aria-describedby="Upgrade to the pricing plan tailored to your unique needs"
                  PaperComponent={CustomPaper}
                >
                    <img src={popup} style={webStyle.dialogImg} alt=""/>
                    <Typography style={webStyle.dialogTitle}>
                        Upgrade to {this.state.level} Pricing Plan
                    </Typography>
                    <Typography style={webStyle.dialogContent}>
                        To upgrade to the pricing plan tailored to your unique needs, please reach out to our Support
                        Team at <a href="mailto:support@carbonzeroed.com"
                                   style={webStyle.emailLink}>support@carbonzeroed.com</a>. We're here to help
                        craft the perfect
                        solution for you!
                    </Typography>
                    <Typography style={webStyle.ctaText}>
                        Go to{" "}
                        <span
                          style={webStyle.ctaLink}
                          onClick={this.handleCloseAmountAlert}
                        >
                            Subscription
                        </span>
                        {" "}page
                    </Typography>
                </Dialog>
            </>
        );
        // Customizable Area End
    }

}

// Customizable Area Start
const webStyle = {
    HeaderContainer: {
        display: "flex",
        justifyContent: 'space-between',
        padding: "0px 48px",
        width: '966px'
    },
    titleContainer: {
        display: "flex",
        flexDirection: 'column',
        gap: '17px'
    } as React.CSSProperties,
    title: {
        fontFamily: 'Lato',
        fontWeight: 700,
        fontSize: "22px",
        lineHeight: "27.5px",
        color: "#34373A",
    },
    description: {
        fontFamily: 'Lato',
        fontWeight: 400,
        fontSize: "12px",
        lineHeight: "19.2px",
        color: "#7D7D7F",
    },
    label: {
        fontFamily: 'Inter',
        fontWeight: 600,
        fontSize: "12px",
        lineHeight: "19.2px",
        color: "#34373A",
        marginTop: '7px'
    },
    switchContainer: {
        display: 'flex',
        gap: '25px',
        alignItems: 'start'
    },
    cardlabel: {
        fontFamily: 'Lato',
        fontWeight: 400,
        fontSize: "18px",
        lineHeight: "24px",
        color: "#34373A",
    },
    priceContainer: {
        display: "flex",
        alignItems: "center",
        gap: "8px",
    },
    price: {
        fontFamily: 'Montserrat',
        fontWeight: 500,
        fontSize: "32px",
        lineHeight: "38px",
        color: "#34373A",
        letterSpacing: "-0.32px"
    },
    permonth: {
        fontFamily: 'Lato',
        fontWeight: 400,
        fontSize: "14px",
        lineHeight: "20px",
        color: "#7D7D7F",
        letterSpacing: "-0.14px"
    },
    innerBox: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        gap: '10px',
        alignSelf: 'stretch',
        paddingBottom: '16px',
        // borderBottom: "1px solid rgba(52, 55, 58, 0.15)"
    } as React.CSSProperties,
    toggletitle: {
        fontFamily: 'Lato',
        fontWeight: 500,
        fontSize: "16px",
        lineHeight: "normal",
        color: "#7D7D7F",
    },
    cardsContainer: {
        borderBottom: "1px solid rgba(52, 55, 58, 0.15)",
        paddingBottom: '32px'
    },
    footertext: {
        fontFamily: 'Lato',
        fontWeight: 400,
        fontSize: "14px",
        lineHeight: "20px",
        color: "#7D7D7F",
        textAlign: "center",
        letterSpacing: "-0.14px",
        padding: '25px 31px 0 35px'
    } as React.CSSProperties,
    cardDatailstitle: {
        fontFamily: 'Lato',
        fontWeight: 700,
        fontSize: "14px",
        lineHeight: "20px",
        color: "#34373A",
        letterSpacing: "-0.14px",
    },
    services: {
        fontFamily: 'Lato',
        fontWeight: 400,
        fontSize: "14px",
        lineHeight: "20px",
        color: "#34373A",
        letterSpacing: "-0.14px",
    },
    subscribe: {
        fontFamily: 'Lato',
        fontWeight: 700,
        fontSize: "14px",
        lineHeight: "normal",
        color: "#329E54",
        display: "flex",
        gap: '13px',
        margin: "8px 0",
        alignItems: 'center'
    },
    terms: {
        fontFamily: 'Lato',
        fontWeight: 400,
        fontSize: "18px",
        lineHeight: "24px",
        color: "#34373A",
    },
    dialogImg: {
        width: "145px",
        height: '129.639px',
        margin: "0 auto",
    } as React.CSSProperties,
    dialogTitle: {
        alignSelf: "stretch",
        color: "var(--greyscale-900, #0F172A)",
        textAlign: "center",
        fontFamily: 'Montserrat',
        fontSize: '24px',
        fontStyle: 'normal',
        fontWeight: 700,
        lineHeight: '125%',
        letterSpacing: '0.2px'
    } as React.CSSProperties,
    dialogContent: {
        alignSelf: "stretch",
        color: '#34373A',
        textAlign: 'center',
        fontFeatureSettings: "'clig' off, 'liga' off",
        fontFamily: "Lato",
        fontSize: '17px',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: '26px',
        letterSpacing: '0.085px',
        margin: '20px 0 30px 0',
    } as React.CSSProperties,
    ctaText: {
        color: 'var(--greyscale-900, #0F172A)',
        textAlign: 'center',
        fontFamily: 'Lato',
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: '160%',
    } as React.CSSProperties,
    ctaLink: {
        color: '#43D270',
        fontFamily: 'Lato',
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: 700,
        lineHeight: '160%',
        cursor: 'pointer'
    },
    emailLink: {
        color: '#43D270',
        cursor: 'pointer'
    }
}
// Customizable Area End
