import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import React from "react";
import * as Yup from "yup";
import { dropdownIcon } from "./assets";
import { base } from "../../DynamicContent/src/DynamicContentController";
import { getStorageData, setStorageData } from "../../../framework/src/Utilities";

export const Colors = {
  inputLabel: "rgba(31, 31, 34, 0.6)",
  inputTextColor: "rgb(31, 31, 34)",
  borderGrey: "rgba(28, 28, 30, 0.3)",
  borderYellow: "rgb(205, 149, 12)",
  white: "#FFFFFF",
  modalBg: "rgb(243, 243, 243)"
};

export interface IntitialValues {
  yourname: string;
  company: string;
  email: string;
  phone: string;
  info: string;
  country: string;
  countryCode: string
}

export interface Task {
  id: number
  title: string
}


// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  description: string;
  description_title: string;
  userDataCall?: () => void;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  getSellerDetailsMessageId: any;
  getCompanyDataMessageId: any;
  companyData: any;
  postSellerDetailsMessageId: any;
  contactusMessageId: any;
  createAccountApiCallId: any;
  DepartmentsApiCallId: any;
  sellerID: any;
  shopName: string;
  address: string;
  gstin: string;
  selectedServices: number[];
  showSuccessModal: boolean;
  token: string;
  lat: any;
  long: any;
  initialValues: IntitialValues;
  signupSchema: any;
  companyDataUpdateStatus: string;
  initialValuesUser: any;
  UserSchema: any;
  departmentList: any;
  isShowForm: boolean;
  errors: any;
  logoimg: string;
  TaskListApiCallId: string;
  tasklist: Task[];
  isLoading: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class CustomformController extends BlockComponent<Props, S, SS> {
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIRequestMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.SessionResponseMessage)
    ];
    const buisness = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9-]+\.[a-zA-Z]{2,}(\.[a-zA-Z]{2,})?$/
    this.state = {
      getSellerDetailsMessageId: "",
      getCompanyDataMessageId: "",
      companyData: "",
      postSellerDetailsMessageId: "",
      DepartmentsApiCallId: "",
      createAccountApiCallId: "",
      contactusMessageId: "",
      TaskListApiCallId: "",
      sellerID: "",
      shopName: "",
      address: "",
      gstin: "",
      selectedServices: [],
      token: "",
      showSuccessModal: false,
      lat: 0,
      long: 0,
      initialValues: {
        yourname: "",
        company: "",
        email: "",
        phone: "",
        info: "",
        country: "",
        countryCode: "in"
      },
      initialValuesUser: {
        fname: "",
        lname: "",
        task: [],
        email: "",
        department: "",
      },
      UserSchema: Yup.object().shape({
        fname: Yup.string()
          .matches(/^[A-Za-z]+$/, "First name should only contain letters.")
          .required("Enter Your First Name"),
        lname: Yup.string()
          .matches(/^[A-Za-z]+$/, "Last name should only contain letters.")
          .required("Enter Your Last Name"),
        email: Yup.string()
          .email("Invalid email")
          .required("Enter Your Company Email")
          .matches(buisness, "Please add Business email"),
      }),
      signupSchema: Yup.object().shape({
        yourname: Yup.string()
          .min(2, "Too Short!")
          .max(50, "Too Long!")
          .required("Enter Your Name"),
        company: Yup.string()
          .min(2, "Too Short!")
          .max(50, "Too Long!")
          .required("Enter Your Company Name"),
        email: Yup.string()
          .email("Invalid email")
          .required("Enter Your Company Email")
          .matches(buisness, "Please add Business email"),
        phone: Yup.string()
          .required("Enter Your Phone Number")
      }),
      companyDataUpdateStatus: '',
      departmentList: [],
      isShowForm: false,
      errors: {},
      logoimg: "",
      tasklist: [],
      isLoading: false,
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);

    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      let token = message.getData(getName(MessageEnum.SessionResponseToken));
      this.setState({ token: token });
      this.getSellerDetail(token);
    }

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (responseJson) {
        this.commonFunction(apiRequestCallId, responseJson)
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener("willFocus", () => {
        this.getToken();
      });
    }
  }

  commonFunction(apiRequestCallId: any, responseJson: any) {
    switch (apiRequestCallId) {
      case this.state.contactusMessageId:
        this.props.navigation.navigate('ContactSuccess', { page: 'contact' })
        break;
      case this.state.getCompanyDataMessageId:
        if (responseJson.errors?.[0]?.token) {
          this.logoutUser();
          this.saveTokenAlert(responseJson.errors[0].token);
          return;
        }
        this.setState({ companyData: responseJson.data, logoimg: responseJson.data.company_data.company_logo });
        break;
      case this.state.DepartmentsApiCallId:
        this.setState({ departmentList: responseJson.departments })
        break;
      case this.state.createAccountApiCallId:
        this.setState({ isLoading: false });
        if (responseJson.success) {
          this.setState({ isShowForm: false,errors:{} })
          if (this.props.userDataCall) {
            this.props.userDataCall()
          }
        } else if (responseJson.errors?.[0]?.token) {
          this.logoutUser();
          this.saveTokenAlert(responseJson.errors[0].token);
        } else {
          this.setState({ errors: responseJson.errors })
        }
        break
      case this.state.TaskListApiCallId:
        if (responseJson.errors?.[0]?.token) {
          this.logoutUser();
          this.saveTokenAlert(responseJson.errors[0].token);
          return;
        }
        this.setState({ tasklist: responseJson.tasks })
        break
    }
  }

  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  labelShopName: string = "Name of Shop";
  labelAddress: string = "Address";
  labelGstin: string = "GSTIN";
  labelAbout: string = "About Us";
  labelServiceProvided: string = "Services Provided";
  labelDealsIn: string = "Deals in";
  labelShopPhotos: string = "Shop Photos(Min 1 - Max 8)";
  labelVisitingCard: string = "Upload Visiting Card";
  btnLabel: string = "Submit";
  btnContinueLabel: string = "Continue";

  services = [
    { label: "Wholesaler", value: 1 },
    { label: "Manufacturer", value: 2 },
    { label: "Hallmarker", value: 3 },
    { label: "Retailer", value: 4 }
  ];

  getSellerDetail = async (token: string) => {
    const header = {
      "Content-Type": configJSON.sellerDetailsApiContentType,
      token: token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.setState({
      getSellerDetailsMessageId: requestMessage.messageId
    });

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.sellersAPIEndPoint + "/1"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getSellersAPIMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  submitSellerDetails = async () => {
    let token = this.state.token;

    if (!token || token.length === 0) {
      this.showAlert("Error", "Invaild Token. Plese log in.");
      return;
    }
    if (this.state.shopName.trim() === "") {
      this.showAlert("Error", configJSON.errorMessageShopName);
      return;
    }

    const header = {
      "Content-Type": configJSON.sellerDetailsApiContentType
    };

    let isWholesalerSelected = false;
    let isRetailerSelected = false;
    let isManufacturerSelected = false;
    let isHallmarking_centerSelected = false;

    this.state.selectedServices.forEach(value => {
      switch (value) {
        case 1:
          isWholesalerSelected = true;
          break;
        case 2:
          isManufacturerSelected = true;
          break;
        case 3:
          isHallmarking_centerSelected = true;
          break;
        case 4:
          isRetailerSelected = true;
          break;
      }
    });

    const httpBody = {
      token: token,
      seller_account: {
        firm_name: this.state.shopName,
        location: this.state.address,
        gstin_number: this.state.gstin,
        wholesaler: isWholesalerSelected,
        retailer: isRetailerSelected,
        manufacturer: isManufacturerSelected,
        hallmarking_center: isHallmarking_centerSelected,
        lat: this.state.lat,
        long: this.state.long
      }
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.setState({
      postSellerDetailsMessageId: requestMessage.messageId
    });

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      this.state.sellerID.length > 1
        ? configJSON.sellersAPIEndPoint + "/" + this.state.sellerID
        : configJSON.sellersAPIEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      this.state.sellerID.length > 1
        ? configJSON.sellerDetailsAPIMethodPUT
        : configJSON.sellerDetailsAPIMethodPOST
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  onChangeInput = (name: string, value: any) => {
    // @ts-ignore
    this.setState({ [name]: value });
  };

  txtInputProps = (key: string) => ({
    // @ts-ignore
    value: this.state[key],
    onChangeText: (text: string) => {
      this.onChangeInput(key, text);
    }
  });

  inputLabelProps = {
    fontSize: 14,
    color: Colors.inputLabel,
    lineHeight: 16
  };

  onServiceSelected = (serviceId: number) => {
    if (!this.state.selectedServices.includes(serviceId)) {
      this.setState({
        selectedServices: this.state.selectedServices.concat(serviceId)
      });
    }
  };

  onServiceUnSelected = (serviceId: number) => {
    this.setState({
      selectedServices: this.state.selectedServices.filter(
        selectedService => selectedService !== serviceId
      )
    });
  };

  showModal = () => {
    this.setState({ showSuccessModal: true });
  };

  hideModal = () => {
    this.setState({ showSuccessModal: false }, () => { });
  };

  submitForm = (value: IntitialValues) => {
    console.log("phonedfsfsdfsd", value.phone)

    const header = {
      "Content-Type": configJSON.sellerDetailsApiContentType
    };

    const httpBody = {
      name: value.yourname,
      company_name: value.company,
      company_email: value.email,
      phone_no: Number(value.phone),
      more_info: value.info
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.setState({
      contactusMessageId: requestMessage.messageId
    });

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.contactusAPIEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.contactusAPIMethodPOST
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  async getCompanyData() {

    const account_id = JSON.parse(localStorage.getItem("userDetails") as string).meta.id;
    const task_id = JSON.parse(sessionStorage.getItem("taskId") as string);
    const organizationid = JSON.parse(localStorage.getItem('organization') as string)?.id
    const account_type = JSON.parse(localStorage.getItem("userDetails") as string).meta.user_account_type

    let httpBody = {
      account_id,
      task_id,
    } as { company_id?: number };

    if (account_type === "portfolio_manager") {
      httpBody.company_id = organizationid
    }

    const header = {
      "Content-Type": configJSON.ContentTypeApplicationJson,
      "token": await getStorageData("authToken")
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.setState({
      getCompanyDataMessageId: requestMessage.messageId
    });

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.endpointGetCompanyData
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.APIMethodTypePost
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  removeimage = () => {
    this.setState({ logoimg: '' })
  }

  updateCompanyDataApi = async (data: any) => {

    const organizationid = JSON.parse(localStorage.getItem('organization') as string)?.id
    const account_type = JSON.parse(localStorage.getItem("userDetails") as string).meta.user_account_type

    const abc = await getStorageData("tasks");

    const taskId = JSON.parse(abc).find((task: any) => task.title === "Map your organization")?.id;

    this.setState({ companyDataUpdateStatus: 'loading' });

    let formData = new FormData();

    formData.append("data[company_name]", data.company_name.trim())
    formData.append("data[company_website]", data.company_website.trim())
    formData.append("data[company_registered_address]", data.company_registered_address.trim())
    formData.append("data[industry_sector_id]", data.industry_sector_id)
    formData.append("data[number_of_employees]", data.number_of_employees)
    formData.append("data[account_id]", data?.account_id)
    formData.append("data[task_id]", taskId)

    if (data.company_logo && typeof (data.company_logo) !== 'string') {
      formData.append("data[company_logo]", data.company_logo)
    } else {
      formData.append("data[company_logo]", this.state.logoimg)
    }

    if (account_type === "portfolio_manager") {
      formData.append("data[company_id]", organizationid)
    }

    const requestOptions = {
      method: configJSON.APIMethodTypePatch,
      body: formData,
      headers: {
        token: localStorage.getItem("authToken") as string,
      }
    };

    try {
      await fetch(base.baseURL + '/' + configJSON.updateCompanyDataEndPoint, requestOptions)
        .then(response => response.json())
        .then((result) => {
          if (result && result.success) {
            this.setState({ companyDataUpdateStatus: 'success' });
            this.getCompanyData()
          } else if (result && result.errors?.[0]?.token) {
            this.logoutUser();
            this.saveTokenAlert(result.errors[0].token);
          } else {
            this.setState({ companyDataUpdateStatus: 'error' });
          }
        })
    } catch (e) {
      this.setState({ companyDataUpdateStatus: 'error' });
    }
  };


  getDepartments() {
    const headers = {
      "Content-Type": configJSON.validationApiContentType
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.setState({ DepartmentsApiCallId: getValidationsMsg.messageId })

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.endpointGetDepartment
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getSellersAPIMethod
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }


  CustomdownIcon = (props: any) => {
    return <img {...props} alt="" style={{ marginRight: "15px" }} src={dropdownIcon} />
  }

  async getTasks() {

    const account_id = JSON.parse(localStorage.getItem("userDetails") as string).meta.id;
    const account_type = JSON.parse(localStorage.getItem("userDetails") as string).meta.user_account_type
    const organizationid = JSON.parse(localStorage.getItem('organization') as string)?.id

    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": await getStorageData("authToken")
    };

    let httpBody = {
      "account_id": account_id,
    } as { account_id: number; company_id?: number };

    if (account_type === "portfolio_manager") {
      httpBody.company_id = organizationid
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.setState({ TaskListApiCallId: requestMessage.messageId })

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.tasklistEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.APIMethodTypePost
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getTaskId =
    // istanbul ignore next
    () => {
    let taskId = "";
    const taskLocal = localStorage.getItem('tasks');
    if (taskLocal && taskLocal !== "") {
      const taskFound = JSON.parse(taskLocal).find((task: any) => task.task_code === "task_2")
      taskId = taskFound?.id;
    }
    return taskId
  }

  handleAddUser = async (value: any) => {
    this.setState({ isLoading: true });
    const account_id = JSON.parse(localStorage.getItem("userDetails") as string).meta.id;
    const account_type = JSON.parse(localStorage.getItem("userDetails") as string).meta.user_account_type
    const organizationid = JSON.parse(localStorage.getItem('organization') as string)?.id
    const taskId = sessionStorage.getItem("taskId") as string;
    const header = {
      "Content-Type": configJSON.sellerDetailsApiContentType,
      "token": await getStorageData("authToken")
    };

    let httpBody = {
      "data": {
        "attributes": {
          "first_name": value?.fname,
          "last_name": value?.lname,
          "user_name": value?.email,
          "email": value?.email,
          "invited_user_id": account_id,
          "internal_department_id": value?.department,
          "url": `https://${window.location.host}/NewPassword`,
          "tasks": value?.task?.map((item: any) => item.id),
          "task_url": `https://${window.location.host}/get-started`,
          "current_task_id": parseInt(taskId),
        } as { company_id?: number }
      }
    };

    if (account_type === "portfolio_manager") {
      httpBody.data.attributes.company_id = organizationid
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.setState({ createAccountApiCallId: requestMessage.messageId });

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.accountCreateEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.contactusAPIMethodPOST
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleClose = () => {
    this.setState({ isShowForm: false,errors:{} })
  }

  handleShowMemberForm = () => {
    this.setState({ isShowForm: true })
  }

  handleErrors(errors: any, touched: any, feild: any) {
    if (errors[feild] && touched[feild]) {
      return true
    } else {
      return false
    }
  }

  isGoNext(errors: any, values: any) {
    const isError = Object.keys(errors || {})?.length === 0
    const valuesToCheck = Object.keys(values).filter(key => (key !== "task") && (key !== "department")).map(key => values[key]);
    const isValue = valuesToCheck.every((item) => item?.length !== 0);
    return isError && isValue ? "#43D270" : "#B1B3C3"
  }

  logoutUser = () => {
    localStorage.clear();
    window.open("/login/primary", "_self");
  }

  saveTokenAlert = (message: string) => {
    const alert = {
      message,
      isOpen: true,
    }

    setStorageData("tokenAlert", JSON.stringify(alert));
  }

  // Customizable Area End
}
