// Customizable Area Start
import React from "react";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, MenuItem, Menu, Snackbar,Popover,Typography,Button } from "@material-ui/core";
import { styled } from "@material-ui/core/styles";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import SwitchButton from "./SwitchButton.web";
import DashboardController from "../DashboardController";
import TablePagination from "./TablePagination.web";
import AddUserForm from "../AddUserForm.web";
import Alert from '@material-ui/lab/Alert';


const tableLabels = {
	username: "User name",
	email: "Email",
	department: "Department",
	tasks: "Pending Tasks",
	status: "Status",
	option: <MoreHorizIcon style={{ color: "#64748B",marginLeft:'7px' }} />,
};

const CustomTableContainer = styled(TableContainer)({
	minHeight: "583px",
	display: 'flex',
	justifyContent: 'space-between',
	flexDirection: 'column',
	marginTop: '49px',
	padding: '12px 24px',
	'@media(max-width: 600px)': {
		padding: 0
	}
});

const CustomTable = styled(Table)({
	width: "100%",
	borderCollapse: "separate",
});

const CustomTableBody = styled(TableBody)({
	color: "inherit",
});

const CustomSpacing = styled("div")({
	height: 16,
	display: "table-header-group",
});

const CustomTableCellHead = styled(TableCell)({
	minHeight: "48px",
	padding: "8px",
	flexShrink: 0,
	borderBottom: "none",
	color: "#7D7D7F",
	fontFamily: "Lato",
	fontSize: "14px",
	fontStyle: "normal",
	fontWeight: 700,
	lineHeight: "21px",
	letterSpacing: "0.2px",
});

const CustomTableCellHeadUsername = styled(TableCell)({
	minHeight: "48px",
	padding: "8px 18px",
	flexShrink: 0,
	borderBottom: "none",
	color: "#7D7D7F",
	fontFamily: "Lato",
	fontSize: "14px",
	fontStyle: "normal",
	fontWeight: 700,
	lineHeight: "21px",
	letterSpacing: "0.2px",
});

const CustomTableCellHeadOptions = styled(TableCell)({
	minHeight: "48px",
	padding: "8px",
	flexShrink: 0,
	borderBottom: "none",
	color: "#7D7D7F",
	fontFamily: "Lato",
	fontSize: "14px",
	fontStyle: "normal",
	fontWeight: 700,
	lineHeight: "21px",
	letterSpacing: "0.2px",
	textAlign: "center",
});
const CustomTableCell = styled(TableCell)({
	borderBottom: "1px solid #E3E3E3",
	padding: "8px",
	color: "#34373A",
	fontFamily: "Lato",
	fontSize: "14px",
	fontStyle: "normal",
	fontWeight: 700,
	lineHeight: "21px",
	letterSpacing: "0.2px",
});

const CustomTableCellUsername = styled(TableCell)({
	borderBottom: "1px solid #E3E3E3",
	padding: "8px 8px 8px 18px",
	color: "#34373A",
	fontFamily: "Lato",
	fontSize: "14px",
	fontStyle: "normal",
	fontWeight: 700,
	lineHeight: "21px",
	letterSpacing: "0.2px",
});

const CustomTableCellStatus = styled(TableCell)({
	minHeight: "48px",
	padding: "8px 8px 8px 0px",
	flexShrink: 0,
	borderBottom: "none",
	color: "#7D7D7F",
	fontFamily: "Lato",
	fontSize: "14px",
	fontStyle: "normal",
	fontWeight: 700,
	lineHeight: "21px",
	letterSpacing: "0.2px",
});

const CustomTableCellOptions = styled(TableCell)({
	borderBottom: "1px solid #E3E3E3",
	padding: "8px",
	color: "#34373A",
	fontFamily: "Lato",
	fontSize: "14px",
	fontStyle: "normal",
	fontWeight: 700,
	lineHeight: "21px",
	letterSpacing: "0.2px",
	textAlign: "center",
});

const CustomTableHead = styled(TableHead)({
	backgroundColor: "#F5F5F5",
	borderRadius: "10px",
});

const CustomTableRowHead = styled(TableRow)({
	// borderRadius: "20px !important",
	overflow: "hidden",
	"& :first-child": {
		borderTopLeftRadius: 10,
		borderBottomLeftRadius: 10,
	},
	"& :last-child": {
		borderTopRightRadius: 10,
		borderBottomRightRadius: 10,
	},
});
const CustomButton = styled(Button)({
	padding:'0',
	minWidth:'0',
	cursor:'pointer'
});
const CustomTypography = styled(Button)({
	padding:'5px 0 0',
	cursor:'pointer',
	textTransform:'capitalize',
	minWidth: 'auto'
});

const CustomPopoverDiv = styled('div')({
	display:'flex',
	flexDirection:'column',
	alignItems:'flex-start'
})

const CustomTableRow = styled(TableRow)({});
export default class UsersTable extends DashboardController {
	constructor(props: any) {
		super(props);
	}

	render() {
		const tableData = this.state.AccountTableData
		const { anchorEl } = this.state;
		return (
			<>
				<Snackbar
					data-test-id={'snackbar-alert'}
					open={this.state.alert.show}
					onClose={() => this.setShowSubmitAlert()}
					autoHideDuration={4000}
					anchorOrigin={{
						vertical: 'top',
						horizontal: 'right',
					}}
				>
					<Alert severity={this.state.alert.status}>
						{this.state.alert.message}
					</Alert>
				</Snackbar>
				<AddUserForm navigation={this.props.navigation} id={""} description={""} description_title={""}
					userDataCall={() => this.getTableData()} />
				<CustomTableContainer>
					<CustomTable aria-label="users table">
						<CustomTableHead>
							<CustomTableRowHead>
								<CustomTableCellHeadUsername>
									{tableLabels.username}
								</CustomTableCellHeadUsername>
								<CustomTableCellHead>{tableLabels.email}</CustomTableCellHead>
								<CustomTableCellHead>{tableLabels.department}</CustomTableCellHead>
								<CustomTableCellHead>{tableLabels.tasks}</CustomTableCellHead>
								<CustomTableCellStatus>{tableLabels.status}</CustomTableCellStatus>
								<CustomTableCellStatus>{tableLabels.option}</CustomTableCellStatus>
							</CustomTableRowHead>
						</CustomTableHead>
						<CustomSpacing />
						<CustomTableBody>
							{tableData && tableData.map((user: any, index: number) => {
								return (
									<CustomTableRow>
										<CustomTableCellUsername>
											{user?.first_name + " " + user?.last_name}
										</CustomTableCellUsername>
										<CustomTableCell>
											{user?.email}
										</CustomTableCell>
										<CustomTableCell>
											{user?.department}
										</CustomTableCell>
										<CustomTableCell>
											{user?.pending_tasks}
										</CustomTableCell>
										<CustomTableCell>
											<SwitchButton index={index} id={user.id} checked={user?.status}
												handleChange={this.handleChange} />
										</CustomTableCell>
										<CustomTableCell>
											<CustomButton key={index} onClick={(event:React.MouseEvent<HTMLButtonElement>) => this.handleOpenPopover(event,user)} data-testid={"popover-" + index}>
											<MoreHorizIcon style={{ color: "#64748B"}} />
											</CustomButton>
											<Popover
												key={index}
												id={Boolean(this.state.openUserModal) ? 'simple-popover' : undefined}
												data-testid={"modal-opened-" + index}
												open={Boolean(this.state.openUserModal)}
												anchorEl={this.state.openUserModal}
												onClose={this.handleCloseUserPopover}
												PaperProps={{
													style: {
													background: 'white',
													boxShadow: "rgba(15, 23, 42, 0.12) 0px 3px 14px",
													overflow: "visible",
													padding:'5px 10px'
													}
												}}
												anchorOrigin={{
												vertical: 'bottom',
												horizontal: 'center',
												}}
												transformOrigin={{
												vertical: 'top',
												horizontal: 'center',
												}}
												>
													<CustomPopoverDiv>
													<CustomTypography data-testid={"delete-user-" + index} onClick={this.handleSecodaryUser}>Delete</CustomTypography>
													{!this.state.secondaryUserStatus &&
													<CustomTypography data-testid={"resend-mail-" + index} 
													onClick={() => 
													this.handleResendMail()
													}>Resend email</CustomTypography>
													}
													</CustomPopoverDiv>
														</Popover>
											</CustomTableCell>
									</CustomTableRow>
								);
							})}
						</CustomTableBody>
					</CustomTable>
					</CustomTableContainer>
				<TablePagination 
					currentPage={this.state.page}
					count={this.state.totalUser}
					onChange={this.handleTablePageChange}
					handleChangeNumber={this.handleTableNumberChange}
					perPage={this.state.perPage} 
				/>
				<Menu
					anchorEl={anchorEl}
					open={Boolean(anchorEl)}
					onClose={this.handleCloseRemove}
				>
					<MenuItem onClick={this.handleRemoveUser}>Remove</MenuItem>
				</Menu>
			</>
		);
	}
}
// Customizable Area End