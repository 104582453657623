import React, { useState } from "react";
import { styled } from "@material-ui/core/styles";
import { checkGreenCircle, verticalDots } from "../assets";
import { Typography, Box, Avatar, Chip } from "@material-ui/core";
import KebabOptions from "./KebabOptions.web";

export const configJSON = require("../config.js");

const LogCard = styled("div")({
  position: "relative",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "flex-start",
  padding: "18px 16px",
  flexShrink: 0,
  borderRadius: "12px",
  width: "100%",
  minHeight: "199px",
  marginBottom: "0",
  backgroundColor: "#ffffff",
  cursor: "pointer",
  "&:hover": {
    backgroundColor: "#ECFBF1"
  }
});

const CardBody = styled("div")({
  minHeight: "131px",
  marginBottom: "0",
  position: "relative",
  width: "100%"
});

const CategoryText = styled(Typography)({
  color: "#7D7D7F",
  fontFamily: "Lato",
  fontSize: "12px",
  fontWeight: 700,
  fontStyle: "normal",
  marginBottom: "16px",
  lineHeight: "160%"
});

const HeadingText = styled(Typography)({
  marginBottom: "8px",
  fontFamily: "Lato",
  fontStyle: "normal",
  color: "#34373A",
  fontSize: "18px",
  fontWeight: 700,
  lineHeight: "140%",
  letterSpacing: "0.2px",
  cursor: "pointer"
});

const ShortDesc = styled(Typography)({
  fontSize: "12px",
  fontFamily: "Lato",
  marginBottom: "24px",
  color: "#7D7D7F",
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "160%",
  overflow: "hidden",
  display: '-webkit-box',
  "-webkit-line-clamp": 2,
  "-webkit-box-orient": "vertical",
});

const ButtonBox = styled("div")({
  display: "flex",
  gap: "5px"
});

const TagPill = styled(Box)({
  fontSize: "12px",
  padding: "8px 15px",
  color: "#7D7D7F",
  fontStyle: "normal",
  fontFamily: "Lato",
  fontWeight: 500,
  lineHeight: "160%",
  height: "32px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: "#F9F9F9",
  borderRadius: "8px",
  textDecoration: "none"
});

const MoreIcon = styled(Box)({
  position: "absolute",
  top: "8px",
  cursor: "pointer",
  right: "4px",
  zIndex: 1
});

const AssignTo = styled(Typography)({
  fontSize: "12px",
  fontFamily: "Lato",
  color: "#7D7D7F",
  fontStyle: "normal",
  fontWeight: 500,
  lineHeight: "160%",
  marginRight: "3px"
});

const Assigned = styled(Typography)({
  fontSize: "12px",
  fontFamily: "Lato",
  color: "#34373A",
  fontStyle: "normal",
  fontWeight: 500,
  lineHeight: "160%"
});

const Profile = styled(Avatar)({
  height: "32px",
  width: "32px",
  marginRight: "6px"
});

const CompletedMark = styled("img")({
  position: "absolute",
  top: "13px",
  right: "42px",
  zIndex: 1
});

const CustomChip = styled(Chip)({
  marginBottom: "10px", 
  fontSize: "12px"
});

const CoreFactorIdText = styled(Typography)({
  color: "#34373A",
    fontFamily: "Lato",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "160%",
    marginLeft: "24px",
    marginRight: "24px",
    "@media all and (max-width: 600px)": {
      margin: "0 10px 0 15px",
    } 
})

const LogEsgFactorCard = (props: any) => {
  const {
    category,
    heading,
    description,
    buttonLabel,
    onClick,
    onSelectOption,
    userType,
    isDisabled,
    assign,
    assigneeImage,
    core_factor_id,
    cardStatus,
    cardFrequencyCode,
  } = props;

  const defaultCategory = category || "Environment";
  const defaultHeading = "Assets leased from others";
  const defaultDescription = `Emissions from operation of assets (e.g. vehicles and facilities) that are leased by the company.`;
  const tagText = "3.8 Upstream Leased Assets";
  const catColor = defaultCategory
    ? configJSON.CategoryColors[defaultCategory]
    : "#7D7D7F";

  const options = [
    cardStatus === "archived" ? "Unarchive" : "Archive",
    "Assign to team member"
  ];
  
  const filteredOptions = cardStatus === 'archived'
  ? options.filter((option, index) => option === "Unarchive")
  : options;
  
  
  if (cardStatus !== 'archived' && core_factor_id === "Core") {
    filteredOptions.shift();
  }

  const [showMoreOptions, setShowMoreOptions] = useState(false);
  const [anchorEl, setAnchorEl] = useState<any>(null);

  const toggleMoreOptions = (
    event: React.MouseEvent<any>,
    moreOptions: boolean
  ) => {
    setShowMoreOptions(!moreOptions);
    if (!moreOptions) {
      setAnchorEl(event.currentTarget);
    } else {
      setAnchorEl(null);
    }
  };

  const handleKababOptions = (value: any) => {
    setShowMoreOptions(false);
    onSelectOption && onSelectOption(value);
  };

  return (
    <Box sx={{ position: "relative" }}>
      {isDisabled && <CompletedMark src={checkGreenCircle} alt="completed" />}
      {userType && cardStatus !== 'completed' ? (
        <MoreIcon>
          <KebabOptions
            data-test-id="kebab_options_test"
            verticalDotIcon={verticalDots}
            onToggleOption={(value: any) =>
              toggleMoreOptions(value, showMoreOptions)
            }
            open={showMoreOptions}
            anchorEl={anchorEl}
            onSelect={handleKababOptions}
            options={filteredOptions}
          />
        </MoreIcon>
      ) : null}
      <LogCard
        style={isDisabled ? { filter: "grayscale(100%)" } : {}}
        onClick={onClick}
      >
        <CardBody>
          <Box display="flex" width="100%">
            <CategoryText style={{ color: catColor }}>
              {category || defaultCategory}
            </CategoryText>
            <CoreFactorIdText>{core_factor_id}</CoreFactorIdText>
            <CustomChip 
              data-test-id="card-freq-code"
              size="small" 
              variant="outlined" 
              label={cardFrequencyCode}
            />
          </Box>
          <HeadingText style={{ opacity: isDisabled ? 0.6 : 1 }}>
            {heading || defaultHeading}
          </HeadingText>
          <ShortDesc>{description || defaultDescription}</ShortDesc>
        </CardBody>
        {assign === null ? (
          <ButtonBox>
            <TagPill>{buttonLabel || tagText}</TagPill>
          </ButtonBox>
        ) : (
          <div style={webStyle.AssignContainer}>
            <Profile src={assigneeImage} alt={assign}/>
            <AssignTo>Assigned to </AssignTo> <Assigned>{assign}</Assigned>
          </div>
        )}
      </LogCard>
    </Box>
  );
};

const webStyle = {
  AssignContainer: {
    display: "flex",
    alignItems: "center"
  },
  DisabledStyle: {
    pointerEvents: "none",
    filter: "grayscale(100%)"
  },
};

export default LogEsgFactorCard;
