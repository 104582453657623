import React from "react";
// Customizable Area Start
import {
  Box, Button, Typography, Paper,
  IconButton,
  Grid, InputLabel, TextField, Radio, List, Divider, ListSubheader,
  InputAdornment,
  CircularProgress,
} from "@material-ui/core";
import { KeyboardDatePicker , MuiPickersUtilsProvider } from "@material-ui/pickers";
import { createTheme, ThemeProvider, styled } from "@material-ui/core/styles";
import MomentUtils from "@date-io/moment";
import { close, checkbox, checked, down, date, time } from "./assets"
import { Formik } from "formik";
import CustomDropDown from "../../../components/src/CustomDropDown.web";
import CustomSnackbar from "../../dashboard/src/common/CustomSnackbar.web";
import moment from "moment";

const theme = createTheme({
  palette: {
    primary: {
      main: "#43D270",
      contrastText: "#fff",
    },
  }
});

const ListItems = styled('button')({
  display: 'flex',
  justifyContent: 'center',
  height: "39px",
  maxWidth: '158px',
  padding: "0px 16px",
  alignItems: "center",
  color: '#34373A',
  marginLeft: '68px',
  gap: "16px",
  borderRadius: "8px",
  cursor: 'pointer',
  border: 'none',
  backgroundColor: '#fff',
  "&:hover": {
    backgroundColor: "#ECFBF1",
    color: "#43D270"
  }
});

const CustomizedButton = styled('button')({
  width: "199px",
  height: "40px",
  backgroundColor: "#FFF",
  color: "#34373A",
  display: "flex",
  padding: "10px 58px 11px 58px",
  justifyContent: "center",
  alignItems: "center",
  gap: "8px",
  textTransform: 'none',
  border: "1px solid #D9D9D9",
  borderRadius: '8px',
  fontWeight: 700,
  fontFamily: "Inter",
  fontSize: "12px",
  fontStyle: "normal",
  lineHeight: "160%",
  cursor: 'pointer'
});

const AddButton = styled('button')({
  width: "100%",
  height: "40px",
  color: "black",
  display: "flex",
  padding: "7px 16px 7px 12px",
  justifyContent: "center",
  alignItems: "center",
  gap: "8px",
  textTransform: 'none',
  fontWeight: 700,
  fontFamily: "Inter",
  fontSize: "12px",
  fontStyle: "normal",
  lineHeight: "160%",
  border: "none",
  borderRadius: '8px',
  cursor: 'pointer'
});

const ListTexts = styled(Typography)({
  fontFamily: "Lato",
  fontStyle: "normal",
  fontWeight: 700,
  lineHeight: "19.2px",
  fontSize: '12px',
  textAlign: 'center',
  "&:hover": {
    color: "#43D270"
  }
});

const webStyle = {
  paperStyle: {
    width: '437px',
    maxWidth: "100%",
    padding: '16px',
    borderRadius: '8px',
    maxHeight: "100vh",
    overflow: 'auto'
  },
  title: {
    color: "#34373A",
    fontFamily: "Lato",
    fontSize: "18px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: " 25.2px",
    letterSpacing: "0.2px"
  },
  desc: {
    color: "#7D7D7F",
    fontFamily: "Lato",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: " 22.4px",
    maxWidth: '395px'
  },
  addbutton: {
    width: "100%",
    height: "40px",
    color: "black",
    display: "flex",
    padding: "7px 16px 7px 12px",
    justifyContent: "center",
    alignItems: "center",
    gap: "8px",
    textTransform: 'none',
    fontWight: 700,
    fontFamily: "Inter",
    fontSize: "12px",
    fontStyle: "normal",
    lineHeight: "160%",
  },
  inputlabels: {
    color: "#34373A",
    fontWeight: 400,
    lineHeight: "22px",
    fontFamily: "Lato",
    fontStyle: "normal",
    fontSize: "15px",
    marginTop: "10px",
  },
  errors: {
    color: "red",
    marginTop: "10px",
    paddingLeft: '10px',
    fontSize: "12px",
    fontWeight: 300,
  },
  inputStyle: {
    border: "#D9D9D9 solid 1px",
    borderRadius: "6px",
    padding: "8.5px 14px",
    font: "inherit",
    height: "48px",
    fontSize: '15px',
  },
  inputext: {
    fontFamily: "Lato",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "15px",
    lineHeight: "24px"
  },
  modelpopper: {
    maxHeight: '271px',
    borderRadius: "8px",
    border: "1px solid #D9D9D9",
    background: "#FFF",
    maxWidth: '404px',
    boxShadow: 'none !important',
    padding: '0px !important',
  },
  resourcename: {
    fontFamily: "Lato",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "24px",
    fontSize: '14px'
  },
  datetimeinput: {
    maxWidth: '194px',
    width: '100%',
    minHeight: '48px',
    display: 'flex',
    height: '48px',
    padding: '9px 15.5px 12px 10px',
    alignItems: 'center',
    gap: '72px',
    alignSelf: 'stretch',
    border: "#D9D9D9 solid 1px",
    borderRadius: "6px",
  },
  timeHeading: {
    fontFamily: "Lato",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "24px",
    fontSize: '16px',
    color: '#34373A',
    letterSpacing: "0.2px",
    textAlign: 'center'
  },
  backButton: {
    width: "199px",
    height: "40px",
    backgroundColor: "#FFF",
    color: "#34373A",
    display: "flex",
    padding: "10px 61px 11px 61px",
    justifyContent: "center",
    alignItems: "center",
    gap: "8px",
    textTransform: 'none',
    border: "1px solid #D9D9D9",
    borderRadius: '8px',
    fontWight: 700,
    fontFamily: "Inter",
    fontSize: "12px",
    fontStyle: "normal",
    lineHeight: "160%",
  }
}

const renderOption = (option: any, { selected }: any) => {
  return (
    <li style={{ maxHeight: "18px", display: 'flex', alignItems: 'center', padding: 0 }}>
      <Radio
        icon={<img src={checkbox} />}
        checkedIcon={<img src={checked} />}
        style={{ marginLeft: -16 }}
        checked={selected}
      />
      <Typography style={{ ...webStyle.resourcename, color: selected ? "#34373A" : "#7D7D7F", }}>{option}</Typography>
    </li>
  );
}

const CustomToolbar = () => null

// Customizable Area End

import AppointmentmanagementController, {
  Props,
  configJSON,
} from "./AppointmentmanagementController";

export default class AddAppointment extends AppointmentmanagementController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    // Customizable Area Start
    return (
      <ThemeProvider theme={theme}>
        <Paper style={webStyle.paperStyle as any}>
          <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography style={webStyle.title}>Create Schedule</Typography>
            <button style={{ paddingRight: '0px', background: '#FFF', border: 'none', cursor: 'pointer' }} onClick={this.props.onClose}> <img src={close} alt="close" /> </button>
          </Box>
          <Typography style={webStyle.desc}>Create your custom schedule to talk to your personal sustainability consultant</Typography>
          {
            this.state.showDateTime === "time" && (
              <>
                <List style={{ marginTop: '14px' }} subheader={<li />}>
                  {this.state.selectedTime && <ListSubheader style={webStyle.timeHeading as any}>{this.state.selectedTime}
                    <img src={down} style={{ position: 'relative', left: '7px', top: '5px' }} />
                  </ListSubheader>}
                  <li>
                    <Divider style={{ color: '#F1F5F9', margin: '15px 0' }} />
                    <ul>
                      {this.state.availableTimes.map((item: string) => {
                        const isSelected = this.state.selectedTime === item;
                        const styles = {
                          backgroundColor: isSelected ? "#ECFBF1" : 'white',
                          color: isSelected ? "#43D270" : "#34373A"
                        }
                        return (
                          <ListItems style={styles} onClick={() => this.selectTime(item)} name={item}>
                            <ListTexts>{item}</ListTexts>
                          </ListItems>
                        )
                      })}
                    </ul>
                  </li>
                </List>
                {this.state.timeError && (
                  <div style={{ ...webStyle.errors, textAlign: 'center', paddingRight: '35px' }}>{this.state.timeError}</div>
                )}
                <Box width={"100%"} display={"flex"} gridGap={11} style={{ marginTop: '52px' }}>
                  <CustomizedButton onClick={this.handlebackTime}>Back</CustomizedButton>
                  <CustomizedButton name="confirmtime" onClick={this.handleConfirmTime} style={{ backgroundColor: '#43D270', border: 'none', padding: '0 !important' } as any}>
                    Confirm Time
                  </CustomizedButton>
                </Box>
              </>
            )}
          {this.state.showDateTime === "date" && (
            <>
              <MuiPickersUtilsProvider utils={MomentUtils}>
                <Box display={"flex"} justifyContent={"center"}>
                  <KeyboardDatePicker 
                    data-test-id="calender"
                    name="calender"
                    autoOk
                    variant="static"
                    openTo="date"
                    shouldDisableDate={this.shouldDisableDate}
                    style={webStyle.datetimeinput}
                    format={"DD-MM-YY"}
                    fullWidth
                    disablePast
                    onMonthChange={this.onMonthChange}
                    value={this.state.available_date}
                    onChange={(date) => this.setDate(date)}
                    ToolbarComponent={CustomToolbar}
                  />
                </Box>
              </MuiPickersUtilsProvider>
              {this.state.timeError && (
                <div style={{ ...webStyle.errors, textAlign: 'center', paddingRight: '35px' }}>{this.state.timeError}</div>
              )}
              <Box width={"100%"} display={"flex"} gridGap={11} style={{ marginTop: '52px' }}>
                <CustomizedButton onClick={this.handlebackTime} name="backdate" >Back</CustomizedButton>
                <CustomizedButton data-test-id="confirmdate" name="confirmdate"
                  onClick={this.handleConfirmDate} style={{ backgroundColor: '#43D270', border: 'none', padding: '0 !important' } as any}>
                  Confirm Date
                </CustomizedButton>
              </Box>
            </>
          )}
          {this.state.showDateTime === "" && (
            <Formik
              enableReinitialize
              initialValues={this.state.initialValues}
              validationSchema={this.state.schema}
              onSubmit={this.handleSubmit}
              data-testid="formik_form"
            >
              {formik => {
                const {
                  values,
                  touched,
                  handleChange,
                  handleSubmit,
                  errors,
                  handleBlur,
                  setFieldValue
                } = formik;
                return (
                  <form onSubmit={handleSubmit} data-testid="dataform">
                    <Grid
                      container
                      spacing={1}
                    >
                      <Grid item xs={12} style={{ minHeight: '108px' }}>
                        <InputLabel
                          htmlFor="title"
                          style={webStyle.inputlabels as any}
                        >
                          Meeting Title
                        </InputLabel>
                        <TextField
                          id="title"
                          data-testid="title"
                          style={webStyle.inputStyle}
                          name="title"
                          placeholder="Enter a title for the meeting"
                          fullWidth
                          variant="standard"
                          InputProps={{
                            disableUnderline: true,
                            style: webStyle.inputext,
                          }}
                          margin="normal"
                          value={values.title}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        <div style={webStyle.errors}>{this.handleErrors(errors, touched, 'title')}</div>
                      </Grid>
                      <Grid item xs={12} style={{ marginTop: '10px' }}>
                        <InputLabel
                          htmlFor="title"
                          style={webStyle.inputlabels as any}
                        >
                          Description
                        </InputLabel>
                        <TextField
                          id="description"
                          data-testid="description"
                          style={{ ...webStyle.inputStyle, maxHeight: '78px !important', height: '78px', overflow: 'auto' }}
                          name="description"
                          placeholder="Write a description, with goals and agenda for the call"
                          fullWidth
                          variant="standard"
                          multiline
                          InputProps={{
                            disableUnderline: true,
                            style: webStyle.inputext,
                          }}
                          margin="normal"
                          value={values.description}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        <div style={webStyle.errors}>{this.handleErrors(errors, touched, 'description')}</div>
                      </Grid>
                      <Grid item xs={12} style={{ marginTop: "-14px" }}>
                        <InputLabel
                          htmlFor="app"
                          style={{ ...webStyle.inputlabels, marginBottom: '8px' } as any}>
                          Select Meeting App
                        </InputLabel>
                        <CustomDropDown
                          value={values.app}
                          List={['Zoho Meeting app', 'Other Custom Link']}
                          onChange={(event: any, newValue: string | null) => {
                            setFieldValue("app", newValue);
                          }}
                          renderOption={renderOption}
                        />
                        <div style={webStyle.errors}>{this.handleErrors(errors, touched, 'app')}</div>
                        {
                          values.app === "Other Custom Link" && (
                            <Grid item xs={12} style={{ marginTop: '46px' }}>
                              <InputLabel
                                htmlFor="link"
                                style={webStyle.inputlabels as any}
                              >
                                Paste Custom Meeting Link Below
                              </InputLabel>
                              <TextField
                                id="link"
                                data-testid="link"
                                style={{ ...webStyle.inputStyle, maxHeight: '78px !important', height: '78px', overflow: 'auto' }}
                                name="link"
                                placeholder="Paste the custom meeting link from Google Meet or Teams in this space"
                                fullWidth
                                variant="standard"
                                multiline
                                InputProps={{
                                  disableUnderline: true,
                                  style: webStyle.inputext,
                                }}
                                margin="normal"
                                value={values.link}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                              <div style={webStyle.errors}>{this.handleErrors(errors, touched, 'link')}</div>
                            </Grid>
                          )
                        }
                      </Grid>
                      <Grid item xs={12} style={{ display: 'flex', gap: '16px', marginTop: "20px" }}>
                        <MuiPickersUtilsProvider utils={MomentUtils}>
                          <Box>
                            <InputLabel
                              htmlFor="date"
                              style={{ ...webStyle.inputlabels, marginBottom: '8px' } as any}>
                              Date
                            </InputLabel>
                            <TextField
                              id="date"
                              data-testid="date"
                              style={{ ...webStyle.datetimeinput }}
                              name="date"
                              placeholder="Select date Slot"
                              fullWidth
                              margin="none"
                              variant="standard"
                              InputProps={{
                                disableUnderline: true,
                                style: webStyle.inputext,
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <img src={date} />
                                  </InputAdornment>
                                ),
                              }}
                              value={values.date ? moment(new Date(values.date)).format("DD-MM-YYYY") : ""}
                              onFocus={() => this.openCalender(values)}
                            />
                            <div style={webStyle.errors}>{this.handleErrors(errors, touched, 'date')}</div>
                          </Box>
                          <Box minWidth={"194px"}>
                            <InputLabel
                              htmlFor="time"
                              style={{ ...webStyle.inputlabels, marginBottom: '8px' } as any}
                            >
                              Time
                            </InputLabel>
                            <TextField
                              id="time"
                              data-testid="time"
                              style={{ ...webStyle.datetimeinput }}
                              name="time"
                              placeholder="Select Time Slot"
                              fullWidth
                              margin="none"
                              variant="standard"
                              InputProps={{
                                disableUnderline: true,
                                style: webStyle.inputext,
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <img src={time} />
                                  </InputAdornment>
                                ),
                              }}
                              value={this.state.selectedTime}
                              onFocus={this.handleChangeTime}
                            />
                            <div style={webStyle.errors}>{this.handleErrors(errors, touched, 'time')}</div>
                            {this.state.timeError && (
                              <div style={webStyle.errors}>{this.state.timeError}</div>
                            )}
                          </Box>
                        </MuiPickersUtilsProvider>
                      </Grid>
                      <Box
                        display="flex"
                        justifyContent="center"
                        mt={3}
                        width="100%"
                      >
                        <AddButton
                          type="submit"
                          name="shedual"
                          data-testid="submitButton"
                          style={{ ...webStyle.addbutton, backgroundColor: this.isGoNext(errors, values) } as any}
                          disabled={!!Object.keys(errors)?.length || this.state.isLoading}
                        >
                          {
                            this.state.isLoading ?
                              <CircularProgress style={{ color: "#FFF" }} size={20} /> :
                              this.isReshedual()
                          }
                        </AddButton>
                      </Box>
                    </Grid>
                  </form>
                );
              }}
            </Formik>
          )}
        </Paper>
        <CustomSnackbar
            dataTestId="alert_snackbar"
            open={this.state.alertMessage.isOpen}
            onClose={this.handleSnackbarClose}
            status="success"
            AlertLabel={this.state.alertMessage.text}
        />
      </ThemeProvider>
    );
    // Customizable Area Start
  }
}
